import http from "../utils/http"; // getFromSessionStorage // getFromLocalStorage,

export class DentalClinicService {
  constructor(config = {}) {
    this.lab_job_categorie_id = config.lab_job_categorie_id;
  }

  static async saveEmployeeLiquidation(reg) {
    return (await http.post("api/dental/liquidation-percent", reg)).data;
  }

  static getProductcategories = async params =>
    (await http.get("api/dental/products/categories", { params })).data;

  static getProformDetails = async params =>
    (await http.get("api/dental/proform-details", { params })).data;

  static getProformDetail = async id =>
    (await http.get(`api/dental/proform-details/${id}`)).data;

  static saveProformDetail = async reg => {
    if (reg.id)
      return (await http.put(`api/dental/proform-details/${reg.id}`, reg)).data;
    else return (await http.post("api/dental/proform-details", reg)).data;
  };

  static deleteProformDetail = async id =>
    (await http.delete(`api/dental/proform-details/${id}`)).data;

  static getClients = async params =>
    (await http.get("api/dental/clients", { params })).data;

  static getClient = async id =>
    (await http.get(`api/dental/clients/${id}`)).data;

  static saveClient = async reg => {
    if (reg.id)
      return (await http.put(`api/dental/clients/${reg.id}`, reg)).data;
    else return (await http.post("api/dental/clients", reg)).data;
  };

  static deleteClient = async id =>
    (await http.delete(`api/dental/clients/${id}`)).data;

  static deleteLaboratoryJob = async id => {
    return (await http.delete(`api/dental/laboratory-jobs/${id}`)).data;
  };

  static getScheduleEmployeeBusyTime = async params => {
    return (
      await http.get("api/service/schedule-times-next-busy-time", {
        params
      })
    ).data;
  };

  static getClientReport = async (params = {}) => {
    return (await http.get("api/dental/reports/new-clients", { params })).data;
  };

  // static enableScheduleTime = async reg => {
  //   return (await http.post("api/general/schedule-disable-times/enable", {
  //     enableTime: 1,
  //     ...reg
  //   })).data;
  // };

  static disableScheduleTime = async reg => {
    return (await http.post("api/general/schedule-disable-times", reg)).data;
  };

  static deleteSale = async id => {
    return (await http.delete(`api/service/sales/${id}`)).data;
  };

  static reqTreatmentDetaillabJob = async reg => {
    return (
      await http.post("api/dental/treatment-plan-details/req-lab-job", reg)
    ).data;
  };

  static payLaboratoryJobs = async reg => {
    return (
      await http.post("api/dental/laboratory-jobs", {
        ...reg,
        to_pay: 1
      })
    ).data;
  };

  static deleteProform = async (id, reason, plan_id) => {
    return (
      await http.delete(`api/dental/proforms/${id}`, {
        params: {
          reason,
          treatment_plan_id: plan_id
        }
      })
    ).data;
  };

  static getProformsReport = async params => {
    return (await http.get("api/dental/proforms/report", { params })).data;
  };

  static saveClientFileStatus = async reg => {
    return (await http.post("api/clinic/client-files/changeStatus", reg)).data;
  };

  static getAgenda = async params => {
    return (await http.get("api/service/agenda", { params })).data;
  };

  /**
   *
   * QUOTES BEGIN
   *
   */
  static getQuotes = async params =>
    (await http.get("api/dental/quotes", { params })).data;

  static getQuote = async id =>
    (await http.get(`api/dental/quotes/${id}`)).data;

  static saveQuote = async reg => {
    if (reg.id)
      return (await http.put(`api/dental/quotes/${reg.id}`, reg)).data;
    else return (await http.post("api/dental/quotes", reg)).data;
  };

  static deleteQuote = async id =>
    (await http.delete(`api/dental/quotes/${id}`)).data;

  static getProgramQuotes = async params => {
    return (
      await http.get("api/dental/program-quotes", {
        params
      })
    ).data;
  };

  static saveOrthodonticPlan = async reg => {
    return (await http.post("api/dental/orthodontic-plans", reg)).data;
  };

  static setTreatmentDiscount = async reg => {
    return (await http.post("api/dental/treatment-plans/set-discount", reg))
      .data;
  };

  static getOrthodontic = async params => {
    return (
      await http.get("api/dental/orthodontics", {
        params
      })
    ).data;
  };

  static getEmployeePayments = async params => {
    return (
      await http.get("api/dental/employee-payments", {
        params
      })
    ).data;
  };

  static saveProgramQuote = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/program-quotes", reg)).data;
    } else {
      return (await http.put(`api/dental/program-quotes/${reg.id}`, reg)).data;
    }
  };

  static getOrthodonticPlan = async params => {
    return (
      await http.get("api/dental/orthodontic-plans", {
        params
      })
    ).data;
  };

  static getTreatmentPlanDetails = async params => {
    return (
      await http.get("api/dental/treatment-plan-details", {
        params
      })
    ).data;
  };

  static saveOrthodontic = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/orthodontics", reg)).data;
    } else {
      return (await http.put("api/dental/orthodontics/" + reg.id, reg)).data;
    }
  };

  // static getOrthodonticHistory = async (client_id) => {
  //   return (await http.get("api/dental/orthodontics", {params: {client_id}})).data;
  // }

  static payDebts = async reg => {
    return (await http.post("api/service/financing-dues?to_pay=1", reg)).data;
  };

  static returnTPPayment = async reg => {
    return (
      await http.put(
        "api/dental/treatment-plans/" + reg.treatment_plan_id,
        reg,
        {
          params: {
            lets_return: 1
          }
        }
      )
    ).data;
  };

  static cancelSale = async reg => {
    return (
      await http.post("api/dental/treatment-plans", reg, {
        params: {
          cancel_sale: 1
        }
      })
    ).data;
  };

  static transferTreatmentPayment = async reg => {
    return (
      await http.put("api/dental/treatment-plans/" + reg.treatment_plan_id, reg)
    ).data;
  };

  static getTreatmentHistory = async params => {
    return (
      await http.get("api/service/treatment-plan-history", {
        params
      })
    ).data;
  };

  static putFinancing = async reg => {
    return (await http.put("api/service/financings/" + reg.id, reg)).data;
  };

  static getContactTasks = async params => {
    return (
      await http.get("api/dental/contact-tasks", {
        params
      })
    ).data;
  };

  static getDebts = async params => {
    return (
      await http.get("api/store/debts", {
        params
      })
    ).data;
  };

  static putFinancingDue = async reg => {
    return (await http.put("api/service/financing-dues/" + reg.id, reg)).data;
  };

  static saveFinancing = async reg => {
    return (await http.post("api/service/financings", reg)).data;
  };

  static getFinancing = async id => {
    return (await http.get("api/service/financings/" + id)).data;
  };

  static getFinancings = async params => {
    return (
      await http.get("api/service/financings", {
        params
      })
    ).data;
  };

  static getFinancingDues = async params => {
    return (
      await http.get("api/service/financing-dues", {
        params
      })
    ).data;
  };

  static payDebtsFromTreatmentPayment = async reg => {
    return (
      await http.post("api/service/financing-dues", reg, {
        params: {
          from_treatment_payment: 1
        }
      })
    ).data;
  };

  static getQuoteComments = async params => {
    return (
      await http.get("api/service/quote-comments", {
        params
      })
    ).data;
  };

  static async saveEmployeePayment(reg) {
    return await http.put("api/dental/employee-payments/" + reg.id, reg);
  }

  static setEmployeePaymentToPay = async (id, toPay) => {
    return await http.put("api/general/employee-payments/" + id, {
      change_to_pay: 1,
      to_pay: toPay
    });
  };

  static saveQuoteComment = async reg => {
    return (await http.post("api/service/quote-comments", reg)).data;
  };

  static getEmployeeConfigs = async params => {
    return (
      await http.get("api/dental/employee-config", {
        params
      })
    ).data;
  };

  static getEmployeeConfig = async id => {
    return (await http.get("api/dental/employee-config/" + id)).data;
  };

  static saveEmployeeConfig = async reg => {
    return (await http.post("api/dental/employee-config", reg)).data;
  };

  getSupplierProducts = async params => {
    params.categorie_id = this.lab_job_categorie_id;
    return (
      await http.get("api/logistic/supplier-products", {
        params
      })
    ).data;
  };

  static deleteSale = async sale_id => {
    return (await http.delete(`api/service/sales/${sale_id}`)).data;
  };

  static deleteTreatmentPlan = async (id, params) => {
    return (
      await http.delete("api/dental/treatment-plans/" + id, {
        params
      })
    ).data;
  };

  static requestLaboratoryJob = async ({
    employee_id,
    lab_job_id,
    comment
  }) => {
    return (
      await http.put("api/dental/laboratory-jobs/" + lab_job_id, {
        employee_id,
        comment,
        lets_req: 1
      })
    ).data;
  };

  static putLaboratoryJob = async reg => {
    return (await http.put("api/dental/laboratory-jobs/" + reg.id, reg)).data;
  };

  static putLaboratoryJobStatus = async ({ list, status }) => {
    return (
      await http.put("api/dental/laboratory-jobs/set-status", {
        list,
        status
      })
    ).data;
  };

  static saveTreatmentPlanDetail = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/treatment-plan-details", reg)).data;
    } else {
      return (
        await http.put(`api/dental/treatment-plan-details/${reg.id}`, reg)
      ).data;
    }
  };

  static getLaboratoryJobs = async params => {
    return (
      await http.get("api/dental/laboratory-jobs", {
        params
      })
    ).data;
  };

  static saveComOdoToTreatment = async reg => {
    return (
      await http.post("api/dental/treatment-plan-details", {
        from_com_odo: 1,
        ...reg
      })
    ).data;
  };

  static setTreatmentPlan = async reg => {
    return (await http.put("api/dental/treatment-plans/" + reg.id, reg)).data;
  };

  static saveProductStatus = async reg => {
    return (await http.post("api/dental/product-states", reg)).data;
  };

  static getProductStates = async params => {
    return (
      await http.get("api/dental/product-states", {
        params
      })
    ).data;
  };

  // static deleteProformDetail = async id => {
  //   return (await http.delete("api/dental/proform-details/" + id)).data;
  // };

  // static saveQuote = async reg => {
  //   if (!reg.id) {
  //     return (await http.post("api/service/quotes", reg)).data;
  //   } else {
  //     return (await http.put("api/service/quotes/" + reg.id, reg)).data;
  //   }
  // };

  static inEvolutionTreatmentDetail = async id => {
    return (
      await http.put("api/dental/treatment-plan-details/" + id, {
        inevolution: true
      })
    ).data;
  };

  static proformChangeDiscount = async (id, discount) => {
    return (
      await http.put("api/dental/proforms/" + id, {
        discount,
        change_discount: 1
      })
    ).data;
  };

  static proformDetailChangeDiscount = async (id, discount) => {
    return (
      await http.put("api/dental/proform-details/" + id, {
        discount,
        change_discount: 1
      })
    ).data;
  };

  static proformDetailChangePrice = async (id, price) => {
    return (
      await http.put(
        "api/dental/proform-details/" + id,
        {
          price
        },
        {
          params: {
            change_price: 1
          }
        }
      )
    ).data;
  };

  static setPriceTreatmentDetail = async (id, price) => {
    return (
      await http.put("api/dental/treatment-plan-details/" + id, {
        setPrice: 1,
        price
      })
    ).data;
  };

  static setDiscountTreatmentDetail = async (id, discount) => {
    return (
      await http.put("api/dental/treatment-plan-details/" + id, {
        discount,
        setDiscount: true
      })
    ).data;
  };

  static setMoneyToTreatment = async (
    client_id,
    money_code,
    treatment_plan_id,
    quantity
  ) => {
    return (
      await http.put("api/dental/treatment-plans/" + treatment_plan_id, {
        client_id,
        money_code,
        quantity,
        add_payment: true
      })
    ).data;
  };

  static setTreatmentPlanDetail = async args => {
    return (
      await http.put("api/dental/treatment-plan-details/" + args.id, args)
    ).data;
  };

  static deleteTreatmentPlanDetail = async id => {
    return (await http.delete("api/dental/treatment-plan-details/" + id)).data;
  };

  static letsToPayTPD = async reg => {
    return (await http.post("api/dental/treatment-plans/pay", reg)).data;
  };

  static deleteOdontogramMenu = async id => {
    return (await http.delete("api/dental/odontogram-menu/" + id)).data;
  };

  static getOdontogramMenu = async params => {
    // return (await getFromSessionStorage("api/dental/odontogram-menu", {
    //   params
    // })).data;
    return (await http.get("api/dental/odontogram-menu", { params })).data;
  };

  static saveOdontogramMenu = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/odontogram-menu", reg)).data;
    } else {
      return (await http.put("api/dental/odontogram-menu/" + reg.id, reg)).data;
    }
  };

  static saveEvolutionTreatment = async reg => {
    return (await http.put("api/dental/treatment-plans/evolution", reg)).data;
  };

  static loadOdontogramToTreatment = async reg => {
    return (await http.post("api/dental/treatment-plans/from-odo-id", reg))
      .data;
  };

  static saveTreatmentPlan = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/treatment-plans", reg)).data;
    } else {
      return (await http.put(`api/dental/treatment-plans/${reg.id}`, reg)).data;
    }
  };

  static saveProformDetail = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/proform-details", reg)).data;
    }
  };

  static deleteProformItemByThId = async th_id => {
    return (await http.delete("api/dental/proform-details/by-th-id/" + th_id))
      .data;
  };

  static getTreatmentPlan = async (id, params) => {
    return (
      await http.get("api/dental/treatment-plans/" + id, {
        params
      })
    ).data;
  };

  static getTreatmentPlans = async params => {
    return (
      await http.get("api/dental/treatment-plans", {
        params
      })
    ).data;
  };

  static getProform = async id => {
    return (await http.get("api/dental/proforms/" + id)).data;
  };

  static getProforms = async params => {
    return (
      await http.get("api/dental/proforms", {
        params
      })
    ).data;
  };

  // static allowDiscount = async (odontogram_id, discount) => {
  //   return (await http.put("api/dental/teeth-history", { odontogram_id, discount })).data;
  // }

  static getTeethHistory = async params => {
    return (
      await http.get("api/dental/teeth-history", {
        params
      })
    ).data;
  };

  static saveToothStatus = async reg => {
    return (await http.post("api/dental/teeth-history", reg)).data;
  };

  static deleteTeethHistory = async id => {
    await http.delete("api/dental/teeth-history/" + id);
    return;
  };

  static getPatientFiles = async arg => {
    return (
      await http.get("api/clinic/client-files", {
        params: arg
      })
    ).data;
  };

  static async saveClientFile(reg) {
    let body = new FormData();
    for(let name in reg) {
      body.append(name, reg[name]);
    }
    let requestOption = { headers: { "Content-Type": "multipart/form-data" } };
    return (await http.post("api/clinic/client-files", body, requestOption)).data;
  }

  static deleteClientFile = async id => {
    await http.delete("api/clinic/client-files/" + id);
    return;
  };

  static saveProForm = async reg => {
    if (!reg.id) {
      return (await http.post("api/dental/proforms", reg)).data;
    } else {
      return (await http.put(`api/dental/proforms/${reg.id}`, reg)).data;
    }
  };

  static changePriceTeethHist = async reg => {
    return (await http.put("api/dental/teeth-history/" + reg.id, reg)).data;
  };

  static getReportProform = async params =>
    (await http.get(`api/dental/reports/proforms`, { params })).data;

  static deleteTreatmentPlanDetails = async params => {
    (await http.delete(`api/dental/treatment-plan-details/group`, { params }))
      .data;
  };

  static reportFinancingDues = async params =>
    (
      await http.get("api/service/financings/report-dues", {
        params
      })
    ).data;

  static reactivateTreatmentPlan = async id => {
    return DentalClinicService.saveTreatmentPlan({
      id,
      reactive: 1
    });
  };
}
